import $ from 'jquery';
import StickyHeader from './StickyHeader';

class Main {
  constructor() {
    var self = this;
    if ($('.js-headerSticky').length) {
      new StickyHeader($('.js-headerSticky')).init();
    }
  }
}

export default Main;
