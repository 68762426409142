import $ from 'jquery';

class StickyHeader {

  constructor($el) {
    this.$el = $el;
  }

  init() {
    this.selectElements()
      .bindEventHandlers()
      .checkScrollPosition();

    return this;
  }

  selectElements() {
    this.$headerMain = $('.js-headerMain').first();

    return this;
  }

  bindEventHandlers() {
    $(window).on('scroll', this.onWindowScroll.bind(this));
    $(window).on('resize', this.onWindowResize.bind(this));

    return this;
  }
  
  onWindowScroll() {
    this.checkScrollPosition();
  }

  onWindowResize() {
    this.checkScrollPosition();
  }

  checkScrollPosition() {
    if (this.$headerMain.length === 0) { return; }
    let clearTop = this.$headerMain.offset().top;
    let clearHeight = this.$headerMain.outerHeight(true);
    let topLimit = clearTop + clearHeight;

    if (window.pageYOffset > topLimit) {
      this.$el.css({
        'display': 'block'
      });
    } 
    if (window.pageYOffset < topLimit) {
      this.$el.css({
        'display': 'none'
      });
    } else {
      this.$el.css({
        'position': 'none'
      });
    }
  }
}

export default StickyHeader;
